// Variable overrides
.bg-violet {
  background-color: #23295d !important;
}
.bg-loginLogo {
  background-color: rgba(221, 223, 226, 0.747) !important;
}
.bg-green {
  background-color: rgba(32, 133, 54, 0.747) !important;
}
.bg-white {
  background-color: rgba(236, 245, 238, 0.747) !important;
}

//#20a8d8

// stylelint-disable
// Bootstrap overrides

// Options
//
// Quickly modify global styling by enabling or disabling optional features.


// Body
//
// Settings for the `<body>` element.

$body-bg: #e5e6e7; //#e4e5e6

// CoreUI Variables



// Navbar

$navbar-height: 60px;
// $navbar-bg: #fff !default;
// $navbar-border: (
//   bottom: (
//     size: 1px,
//     style: solid,
//   ),
// );

// // Breakpoints for mobile
// $breakpoint-mobile-up: lg !default;
// $breakpoint-mobile-down: md !default;

.bg-info-isRead{
  background-color: #63c2de18 !important;
}
.bg-success-isRead{
  background-color: #4dbd7418 !important;
}
.bg-warning-isRead{
  background-color: #ffc10718 !important;
}
.bg-danger-isRead{
  background-color: #f86c6b18 !important;
}
.list-group-item-divider{
  cursor: pointer;
}

.list-group-item-load-more {
  cursor: pointer;
  border: 0px !important;
  margin-bottom: 10px !important;
}