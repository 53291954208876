$icomoon-font-family: "codicon" !default;
$icomoon-font-path: "fonts" !default;

$coni-herd: "\e901";
$coni-cow: "\e902";
$coni-happycow: "\e900";
$coni-home: "\e90b";
$coni-price-tag: "\e935";
$coni-price-tags: "\e936";
$coni-qrcode: "\e938";
$coni-calendar: "\e953";
$coni-equalizer: "\e993";
$coni-cog: "\e994";
$coni-cogs: "\e995";
$coni-stats-dots: "\e99b";
$coni-stats-bars: "\e99c";
$coni-leaf: "\e9a4";
$coni-meter: "\e9a6";
$coni-clipboard: "\e9b8";
