@import "var";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ef3d9l');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ef3d9l#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?ef3d9l') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?ef3d9l') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?ef3d9l##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="coni-"], [class*=" coni-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: 600;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.coni-herd {
  &:before {
    content: $coni-herd;
  }
}
.coni-cow {
  &:before {
    content: $coni-cow;
  }
}
.coni-happycow {
  &:before {
    content: $coni-happycow;
  }
}
.coni-home {
  &:before {
    content: $coni-home;
  }
}
.coni-price-tag {
  &:before {
    content: $coni-price-tag;
  }
}
.coni-price-tags {
  &:before {
    content: $coni-price-tags;
  }
}
.coni-qrcode {
  &:before {
    content: $coni-qrcode;
  }
}
.coni-calendar {
  &:before {
    content: $coni-calendar;
  }
}
.coni-equalizer {
  &:before {
    content: $coni-equalizer;
  }
}
.coni-cog {
  &:before {
    content: $coni-cog;
  }
}
.coni-cogs {
  &:before {
    content: $coni-cogs;
  }
}
.coni-stats-dots {
  &:before {
    content: $coni-stats-dots;
  }
}
.coni-stats-bars {
  &:before {
    content: $coni-stats-bars;
  }
}
.coni-leaf {
  &:before {
    content: $coni-leaf;
  }
}
.coni-meter {
  &:before {
    content: $coni-meter;
  }
}
.coni-clipboard {
  &:before {
    content: $coni-clipboard;
  }
}
